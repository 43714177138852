@import url('https://fonts.googleapis.com/css?family=Work+Sans&display=swap');
$main_color: #152d64;

// break points
$break-medium: 1060px;
$break-small: 750px;
$break-x-small: 448px;

* {
    box-sizing: border-box;
    font-family: 'Work Sans', sans-serif;
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: $main_color;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: .55rem;
    }
    &::-webkit-scrollbar-track {
        background: $main_color;
    }
    &::-webkit-scrollbar-thumb {
        background: white;
    }
}

.app-container {
    overflow-x: hidden;

    .nav {
        display: flex;
        width: 100%;
        background-color: white;
        height: 3rem;
        position: fixed;
        z-index: 2;

        img {
            width: 40px;
            height: 40px;
            margin: auto 0;
            position: absolute;
            left: 2rem;
            top: 50%;
            bottom: 50%;
        }

        .links {
            display: flex;
            justify-content: space-around;
            width: 60%;
            margin: auto 15rem;
            
            @media screen and (max-width: $break-medium) {
                width: 80%;
                margin: auto 5rem;
                font-size: 1.25rem;
            }
            @media screen and (max-width: $break-small) {
                display: none;
            }

            a {
                text-decoration: none;
                font-size: 1.5rem;
                color: $main_color;
                cursor: pointer;
                height: 3rem;
                width: 8rem;
                text-align: center;
                padding: .5rem 0 0 0;

                @media screen and (max-width: $break-medium) {
                    font-size: 1.25rem;
                }
            }

            .active {
                background-color: $main_color;
                color: white;
            }
        }
    }

    .phone-nav {
        display: none;

        @media screen and (max-width: $break-small) {
            display: flex;
            justify-content: space-between;
            padding: 0 2rem;
        }

        img {
            position: unset;
        }

        .phone-menu-icon {
            margin: auto 0;
            cursor: pointer;
            svg {
                width: 2rem;
                height: 2rem;
                fill: $main_color;
            }
        }

        .phone-nav-dropdown {
            position: absolute;
            top: 2.5rem;
            background-color: white;
            z-index: 100;
            height: 100vh;
            width: 60vw;
            right: 0;
            background-color: rgba(255, 255, 255, 0.938);

            .links {
                display: flex;
                flex-direction: column;
                margin: 5rem auto;

                div {
                    padding: 0.75rem 0;
                    a {
                        color: $main_color;
                        font-size: 2.25rem;
                        text-transform: uppercase;

                        @media screen and (max-width: $break-x-small) {
                            font-size: 2rem;
                        }
                    }
                    .phone-nav-active {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .desktop-nav {
        @media screen and (max-width: $break-small) {
            display: none;
        }
    }

    .content-container {
        background-color: $main_color;
        display: flex;
        height: 100%;
        margin: 3rem 0 0 0;
        overflow-x: hidden;

        .additionalLinksTab {
            position: absolute;
            background-color: white;
            z-index: 1;
            right: 0;
            width: 12rem;
            top: 0;
            display: none;
            justify-content: center;
            text-transform: uppercase;
            border-bottom-left-radius: 0.25rem;

            @media screen and (max-width: $break-small) {
                display: flex;
                flex-direction: column;
            }

            .activate-wrapper {
                display: flex;
                position: absolute;
                top: 0;
                margin: 0.5rem 0 2rem 0;
            }

            .list {
                display: flex;
                flex-direction: column;
                text-align: center;
                height: 10rem;
                position: relative;
                margin-top: 3rem;

                a {
                    margin: 1rem;
                    color: $main_color;
                    font-weight: bold;
                }
            }

            p {
                margin: auto 0.25rem;
            }

            .arrow {
                display: flex;
                justify-content: center;
                height: 24px;
                margin: auto 0.25rem;
                
                svg {
                    transform: scale(0.75);
                    fill: black;
                }
            }
        }

        .Training {
            width: 100%;
            min-height: 95%;
            position: absolute;
            background-color: $main_color;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .nav-settings {
                display: flex;
                justify-content: center;
                align-items: center;

                @media screen and (max-width: $break-small) {
                    flex-direction: column;
                }

                a {
                    margin: 0 1rem;

                    @media screen and (max-width: $break-small) {
                        margin: 1rem;
                    }
                }

                .selected-training-Nav {
                    background-color: white;
                    color: $main_color;
                    font-size: 1.5rem;
                    min-width: 10rem;
                    text-align: center;
                    text-transform: uppercase;
                    cursor: pointer;
                    border-radius: .25rem;
                    transition: all ease-in-out .3s;

                    &:hover {
                        background-color: transparent;
                        color: white;
                    }
                }
            }

            iframe {
                min-width: 70vw;
                min-height: 70vh;
                align-self: center;
                border: none;
                margin: 2rem 0;
                overflow: none;
            }
            .spinner {
                position: absolute;
                top: 50vh;
                left: 50%;
                transform: scale(1.75);
            }

            .small-iframe {
                @media screen and (max-width: $break-x-small) {
                    width: 95% !important;
                    height: 490rem !important;
                }
            }

            .selected-training-heading {
                text-transform: uppercase;
                text-align: center;
                width: 80%;
            }

            .selected-training-goBack {
                color: white;
                text-align: center;
                text-transform: uppercase;
                font-size: 1.5rem;
                cursor: pointer;
                margin: 1rem 0;
            }

            .training-type {
                font-size: 2rem;
                color: white;
                margin: 5rem 0 0 6rem;
                text-transform: uppercase;

                
                @media screen and (max-width: $break-x-small) {
                    text-align: center;
                    margin: 5rem 0 0 0;
                }
            }

            .listings {
                width: 70%;
                display: grid;
                grid-template-columns: repeat(auto-fit, 14rem);
                position: relative;
                padding: 0 0 0 5rem;
                margin: 5rem 0 0 0;

                @media screen and (max-width: $break-x-small) {
                    display: flex;
                    flex-direction: column;
                    margin: 5rem auto 0 auto;
                    padding: 0;
                }

                .title {
                    position: absolute;
                    top: -3rem;
                    left: 6.2rem;
                    border-bottom: 2px solid white;
                    max-width: 20rem;
                    min-width: 12rem;
                    width: 20%;
                    text-align: left;
                    font-size: 1.5rem;
                    color: white;
                    text-transform: uppercase;

                    @media screen and (max-width: $break-x-small) {
                        text-align: center;
                        left: 8.5%;
                    }
                }

                
                .note {
                    width: 20rem;
                    margin: 1rem 0 1rem 1.25rem;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;

                    @media screen and (max-width: $break-x-small) {
                        width: 12rem;
                    }

                    p {
                        color: white;
                        width: 100%;
                    }
                }
                
                .traininglink {
                    width: 12rem;
                    height: 12rem;
                    display: flex;
                    flex-direction: column;
                    border: 2px solid white;
                    margin: 1.25rem;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    text-align: center;
                    border-radius: .75rem;
                    text-transform: uppercase;

                    h4 {
                        width: 98%;
                    }

                    .icon-holder {
                        display: flex;
                        width: 100%;
                        justify-content: space-evenly;
                        margin: 2rem 0;

                        .pen-wrapper,
                        .form-wrapper,
                        .book-wrapper,
                        .exam-wrapper {
                            transition: ease-in-out .3s;
                            cursor: pointer;
                            svg {
                                width: 2.5rem;
                                height: 2.5rem;
                                fill: white;
                            }

                            &:hover {
                                transform: scale(1.1);
                            }
                        }

                        .form-wrapper {
                            svg {
                                width: 3rem;
                                height: 3rem;
                            }
                        }
                    }
                }

            }

            .note-listings {
                display: flex;
                flex-direction: column;
            }

            h2 {
                align-self: center;
                margin: 3rem 0;
                font-size: 3rem;
                color: white;

                @media screen and (max-width: $break-small) {
                    font-size: 2rem;
                }
            }

            .select-training {
                display: flex;
                flex-direction: column;
                align-self: center;
                    
                    a {
                        max-width: 30rem;
                        border: 2px solid white;
                        margin: 1rem 0;
                        border-radius: 0.75rem;
                        text-align: center;
                        padding: 1.1rem 0;
                        color: white;
                        text-decoration: none;
                        font-size: 2rem;
                        text-transform: uppercase;
                        cursor: pointer;
                        transition: ease-in-out .3s;
                        text-decoration: none;
                        color: white;

                        @media screen and (max-width: $break-small) {
                            font-size: 1.5rem;
                        }

                        &:hover {
                            transform: scale(1.075);
                        }
                    }

            }
        }
        

        .documentsHolder {
            width: 95vw;
            position: absolute;
            background-color: $main_color;
            padding: 0 0 0 2rem;

            @media screen and (max-width: $break-x-small) {
                padding: 0;
                display: flex;
                flex-direction: column;
                margin: 0 auto;
            }
            .title-wrapper {
                display: flex;
                max-width: 35rem;
                margin: 0 0 0 3rem;

                @media screen and (max-width: $break-small) {
                    flex-direction: column;
                    margin: 2rem auto;
                }

                .title {
                    margin: 2rem 2rem 2rem 0;
                    color: rgba(255, 255, 255, 0.397);
                    font-size: 1.55rem;
                    text-align: center;
                    cursor: pointer;
                    text-transform: uppercase;
                    visibility: visible;

                    @media screen and (max-width: $break-small) {
                        margin: 1rem auto;
                    }
                }

                .active {
                    color: white;
                }
            }

            .oas-cards {
                display: grid;
                grid-template-columns: repeat(auto-fit, 14rem);
                margin: 5rem 0 0 0;
            }

            .additionalLinks {
                position: fixed;
                top: 3rem;
                right: 0;
                display: block;

                @media screen and (max-width: $break-small) {
                    display: none;
                }

                .links {
                    display: flex;
                    flex-direction: column;
                    font-size: 1.25rem;

                    a {
                        color: white;
                        text-decoration: underline;
                        text-align: left;
                        margin: 1rem;
                        text-transform: capitalize;
                    }
                }
            }

            .documentWrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                a {
                    text-align: center;
                    text-decoration: none;

                    .documentSVG {
                        transition: ease-in-out 0.3s;
                        &:hover {
                            transform: scale(1.1);
                        }
                        svg {
                            width: 150px;
                            height: 150px;
                            padding: 0 auto;
                            transform: scale(1.1);
                            fill: white;
                        }  
                    } 
                    p {
                        color: white;
                        font-size: 1.5rem;
                        margin: 2rem 0;
                        width: 150px;
                        text-transform: uppercase;
                    }
                }
            }

            .maindocs {
                display: grid;
                grid-template-columns: repeat(auto-fit, 14rem);
                margin: 5rem auto 0 auto;
            }

            .segregatedPDFs {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                @media screen and (max-width: $break-x-small) {
                    align-items: center;
                }

                .title {
                    width: 20rem;
                    margin: 2rem 0 2rem 3rem;
                    border-bottom: 2px solid white;
                    color: white;
                    font-size: 1.55rem;
                    text-transform: capitalize;

                    @media screen and (max-width: $break-x-small) {
                        margin: 2rem 0 2rem 0rem;
                        text-align: center;
                        width: 80%;
                    }
                }

                .documents {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, 14rem);
                }
            }
        }
    }
}
